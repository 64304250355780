<template>
  <div>
    <!-- Start -->
    <section class="relative md:py-24 py-16" id="contact">
      <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center">
          <h2>Contatti</h2>
        </div><!--end grid-->

        <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 gap-6">
          <div class="lg:col-span-4">
            <div class="lg:ms-8">
              <div class="flex">
                <div class="icons text-center mx-auto">
                  <i class="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                </div>

                <div class="flex-1 ms-6">
                  <h5 class="text-lg dark:text-white mb-2 font-medium">Telefono</h5>
                  <a href="tel:3355730298" class="text-slate-400">335 5730298</a>
                </div>
              </div>
            </div>
          </div>
          <div class="lg:col-span-4">
            <div class="lg:ms-8">
              <div class="flex">
                <div class="icons text-center mx-auto">
                  <i class="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                </div>

                <div class="flex-1 ms-6">
                  <h5 class="text-lg dark:text-white mb-2 font-medium">Email</h5>
                  <p>Per informazioni: <a href="mailto:veloxsicurezza@gmail.com" class="text-slate-400">veloxsicurezza@gmail.com</a></p>
                  <p class="mt-2">Posta certificata: <a href="mailto:veloxsicurezza@pec.it" class="text-slate-400">veloxsicurezza@pec.it</a></p>
                </div>
              </div>

            </div>
          </div>
          <div class="lg:col-span-4">
            <div class="lg:ms-8">
              <div class="flex">
                <div class="icons text-center mx-auto">
                  <i class="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                </div>

                <div class="flex-1 ms-6">
                  <h5 class="text-lg dark:text-white mb-2 font-medium">Dove siamo</h5>
                  <p class="text-slate-400 mb-2">Viale Montegrappa, 220/H, 59100 Prato PO</p>
                </div>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->

  </div>
</template>

<script>
export default {
  name: 'ContactSection'
}
</script>