<template>
  <div>
    <section class="relative bg-gray-50 md:py-24 py-16 dark:bg-slate-800" id="workWithUs">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
          <div class="lg:col-span-5">
            <div class="relative">
              <img src="@/assets/images/investigatore-privato.png" class="rounded-lg shadow-lg relative" alt="">
            </div>
          </div><!--end col-->

          <div class="lg:col-span-7">
            <div class="lg:ms-7">
              <h2>Lavora con noi</h2>

              <p class="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                Ricerchiamo operatori da adibire ai Servizi Investigativi, Addetti ai Servizi di Controllo (ex-buttafuori)
                per eventi e manifestazioni, Addetti al Portierato non armato, Antitaccheggio, Servizio Antincendio e Primo Soccorso.
              </p>

              <p class="text-slate-400 dark:text-slate-300 max-w-2xl mt-4 mx-auto">
                Se sei interessato o sei già un addetto professionista non esitare a contattarci! Invia curriculum,
                attestato e documento a
                <a href="mailto:veloxsicurezza@gmail.com" class="text-slate-600">veloxsicurezza@gmail.com</a> e
                provvederemo a contattarti.
              </p>
            </div>
          </div><!--end col-->
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->
  </div>
</template>


<script>
export default {
  name: 'AboutSection',
  methods: {
    ScrollIntoView(elem) {
      this.active = elem;
      let ele = document.querySelector(elem);
      ele.scrollIntoView({behavior: 'smooth', block: 'start'});
    },
  }
}
</script>
