<template>
    <div>
        <section class="relative md:py-24 py-16" id="services">
            <div class="container lg mx-auto">
                <div class="items-center">
                    <div>
                        <h2>I nostri servizi</h2>
                    </div>
                </div><!--end grid-->
                <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
                    <div v-for="(item, index) in services" :key="index"
                        class="features p-6 hover:shadow-xl hover:shadow-slate-200 transition duration-500 rounded-3xl mt-2 sm:mt-8">
                        <div
                            class="w-20 h-20 bg-base-600/5 text-base-600 rounded-xl text-5xl flex align-middle justify-center items-center shadow-sm">
                            <i :class="`uil uil-${item.logo}`"></i>
                        </div>

                        <div class="content mt-7">
                            <a class="text-lg hover:text-base-600 dark:text-white dark:hover:text-base-600 transition-all duration-500 ease-in-out font-medium">
                            {{item.title}}
                            </a>
                            <p class="text-slate-400 mt-3" v-html="item.desc"></p>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
    <!-- End -->
</div></template>
  
<script>
export default {
    name: 'ServiceSection',
    data() {
        return {
            services: [
                {
                    logo: 'users-alt',
                    title: 'Settore privato',
                    desc: '<ul class="list list-disc">' +
                            '<li>Indagini nell\'ambito familiare, matrimoniale e separazioni</li>' +
                            '<li>Rintraccio persone</li>' +
                            '<li>Controllo minori</li>' +
                          '</ul>'
                },
                {
                    logo: 'building',
                    title: 'Settore aziendale',
                    desc: '<ul class="list list-disc">' +
                            '<li>Concorrenza Sleale</li>' +
                            '<li>Indagini contro l’assenteismo ed infedeltà dipendenti</li>' +
                            '<li>Investigazioni su licenziamenti per giusta causa</li>' +
                            '<li>Tutela marchi e brevetti</li>' +
                            '<li>Bonifica telefonica e ambientale</li>' +
                          '</ul>'
                },
                {
                    logo: 'shield-check',
                    title: 'Settore della sicurezza',
                    desc:  '<ul class="list list-disc">' +
                            '<li>Assistenza ad enti pubblici, body guarding, hostess, steward</li>' +
                            '<li>Gestione eventi e manifestazioni pubbliche e private</li>' +
                            '<li>Addetti ai servizi di controllo, personale antincendio e di primo soccorso sanitario</li>' +
                            '<li>Portierato, reception, guardiania</li>' +
                          '</ul>'
                },
            ]
        }
    }
}
</script>
  