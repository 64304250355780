<template>
	<!-- Navbar Start -->
	<nav id="navbar" class="navbar">
		<div class="container flex flex-wrap items-center justify-end">
      <img id="logo-dark" alt="Velox Sicurezza" class="filter-white-responsive inline-block h-14" src="@/assets/images/logo-velox-sicurezza.png">

			<div class="nav-icons flex items-center lg_992:order-2 ms-auto sm:hidden xl:block">
				<!-- Navbar Collapse Manu Button -->
				<button aria-controls="menu-collapse" aria-expanded="false" class="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
					data-collapse="menu-collapse" type="button" @click="toggleMenu()">
					<span class="sr-only">Menu</span>
					<i class="mdi mdi-menu mdi-24px"></i>
				</button>
			</div>

			<!-- Navbar Manu -->
			<div id="menu-collapse" class="navigation lg_992:order-2 lg_992:flex hidden">
				<ul id="navbar-navlist" class="navbar-nav nav-light">
					<li :class="{ 'active': active === '#home' }" class="nav-item">
						<a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#home')" >Home</a>
					</li>
					<li :class="{ 'active': active === '#services' }" class="nav-item">
						<a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#services')">Servizi</a>
					</li>
					<li :class="{ 'active': active === '#workWithUs' }" class="nav-item">
						<a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#workWithUs')">Lavora con noi</a>
					</li>
					<li :class="{ 'active': active === '#contact' }" class="nav-item">
						<a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#contact')">Contatti</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
	<!-- Navbar End -->
</template>
<script>

export default {
	name: 'NavbarSection',
	data() {
		return {
			active: '#home'
		}
	},
	created() {
		document.documentElement.classList.add('light');
		document.documentElement.setAttribute('dir', 'ltr');

		var bodyElement = document.body;
		bodyElement.classList.add('font-rubik', 'text-base', 'text-slate-900', 'dark:text-white', 'dark:bg-slate-900');

		window.addEventListener('scroll', (ev) => {
			ev.preventDefault();
			const navbar = document.getElementById("navbar");
			const logoDark = document.getElementById("logo-dark");
			if (
				document.body.scrollTop >= 50 ||
				document.documentElement.scrollTop >= 50
			) {
        navbar.classList.add("is-sticky");
        if (!this.isMobile()) {
          logoDark.classList.remove("filter-white");
        }
			} else {
				navbar.classList.remove("is-sticky");
        if (!this.isMobile()) {
          logoDark.classList.add("filter-white");
        }
			}


			var mybutton = document.getElementById("back-to-top");
			if (mybutton != null) {
				if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
					mybutton.classList.add("block");
					mybutton.classList.remove("hidden");
				} else {
					mybutton.classList.add("hidden");
					mybutton.classList.remove("block");
				}
			}
		});
	},
	methods: {
		ScrollIntoView(elem) {
			this.active = elem;
			let ele = document.querySelector(elem);
			ele.scrollIntoView({behavior: 'smooth', block: 'start'});
      this.toggleMenu();
		},
		toggleMenu() {
			let ele = document.getElementById('menu-collapse');
			ele.classList.toggle('hidden');
		},
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
      );
    }
	}
}

</script>