<template>
	<div>
		<!-- Start Footer -->
		<footer class="py-8 bg-base-800">
			<div class="container">
				<div class="grid md:grid-cols-12 items-center">
					<div class="md:col-span-3">
						<a class="logo-footer" href="#">
							<img alt="Velox Sicurezza" class="md:ms-0 mx-auto filter-white" src="@/assets/images/logo-velox-sicurezza.png">
						</a>
					</div>

					<div class="md:col-span-6 md:mt-0 mt-8">
						<div class="text-center">
							<p class="text-gray-300">© {{ new Date().getFullYear() }} - Velox Sicurezza SRL</p>
							<p class="text-gray-300">C.F. e P.Iva 02414290979</p>
						</div>
					</div>

					<div class="md:col-span-3 md:mt-0 mt-8 flex flex-col items-center gap-y-1">
            <p class="text-gray-300">In partnership con:</p>
            <a href="https://www.checkservicesteward.it" target="_blank">
              <img class="filter-white h-24" alt="Check Service" src="@/assets/images/logo-check.png">
            </a>
            <a href="https://www.veloxinvestigazioni.it/" target="_blank">
              <img class="filter-white h-24" alt="Check Service" src="@/assets/images/logo-velox.png">
            </a>
					</div><!--end col-->
				</div><!--end row-->
        <div class="mt-8 w-full flex justify-center gap-4">
          <a href="https://www.iubenda.com/privacy-policy/76078450" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe text-white" title="Privacy Policy ">Privacy Policy</a>
          <a href="https://www.iubenda.com/privacy-policy/76078450/cookie-policy" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
          <a href="https://www.iubenda.com/termini-e-condizioni/76078450" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Termini e Condizioni ">Termini e Condizioni</a>
        </div>
			</div><!--end container-->
		</footer><!--end footer-->
		<!-- End Footer -->

	</div>
</template>



<script type="text/javascript">
export default {
  name: 'FooterSection'
};

// Iubenda
(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
// let _iub = _iub || [];
// _iub.csConfiguration = {"siteId":3797229,"cookiePolicyId":13532184,"lang":"it","storage":{"useSiteId":true}};
</script>
