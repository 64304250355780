<template>
	<div>
		<NavbarSection/>
		<!-- Hero Start -->
		<section v-memo="['cache']" class="py-36 lg:py-48 w-full table relative bg-center bg-cover" :style="{ backgroundImage: `url(${HeroBackground})` }" id="home">
			<div class="absolute inset-0 bg-black opacity-85"></div>
			<div class="container relative">
				<div class="grid grid-cols-1">
					<h1 class="text-white text-6xl lg:leading-normal leading-normal font-medium mb-7 position-relative font-sierra-madre uppercase">
            Velox Sicurezza
          </h1>
					<h4 class="hidden sm:block text-white lg:text-4xl text-3xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
						<br>Operiamo nel settore <span class="typewrite relative text-type-element" ref="typedText" data-type='[ "Privato", "Aziendale", "Sicurezza" ]'></span>
					</h4>

					<p class="text-white opacity-60 mb-6 max-w-2xl text-lg">
            La Velox Sicurezza è un istituto di indagini, ricerca, sicurezza ed investigazioni regolarmente autorizzata dall'Autorità Prefettizia di Prato.
          </p>
				</div>
			</div><!--end container-->
		</section><!--end section-->
		<!-- Hero End -->

		<ServiceSection/>
		<WorkWithUseSection/>
		<ContactSection/>
		<FooterSection/>
	</div>
</template>

<script>
import NavbarSection from '../components/NavbarSection.vue';
import ServiceSection from '../components/ServiceSection.vue';
import WorkWithUseSection from '../components/WorkWithUsSection.vue';
import ContactSection from '../components/ContactSection.vue';
import FooterSection from '../components/FooterSection.vue';
import Typed from 'typed.js';
import HeroBackground from '../assets/images/bg/security.jpg';

export default {
	name: 'HomeIndex',
	components: {
		NavbarSection,
		ServiceSection,
		WorkWithUseSection,
		ContactSection,
		FooterSection,
	},
	data() {
		return {
			typed: null,
      HeroBackground
		};
	},
	mounted() {
		this.setupTyped();
	},
	methods: {
		setupTyped() {
			const options = {
				strings: [ "Privato", "Aziendale", "Sicurezza" ],
				typeSpeed: 50,  // Typing speed in milliseconds
				backSpeed: 30,  // Backspacing speed in milliseconds
				loop: true,     // Set to true to loop through strings
			};

			this.typed = new Typed(this.$refs.typedText, options);
		},
	},

}
</script>
